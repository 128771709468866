<script lang="ts">
  import Arrow from "../../components/arrow/Arrow.svelte";
  import {calcDistance, calcCentralizedEvent} from './distanceService.js'
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let initialRotation
  const TURN_EVENT_NAME = 'turn';

  let swipeElement;
  let state = {
    started: false,
    lastDistance: 0,
    finalDegree: 0,
    lastTouch: {left: 0, top: 0},
  }
  $: state.finalDegree = initialRotation || 0

  function getTouchPoint(e) {
    return e.targetTouches?.[0] || e;
  }

  function onTouchMove(e) {
    if (!state.started) {
      return;
    }
    const x = getTouchPoint(e).clientX
    const y = getTouchPoint(e).clientY;
    const newTouch = calcCentralizedEvent(swipeElement, x, y);
    const distance = calcDistance(newTouch, state.lastTouch);
    state = {
      ...state,
      lastDistance: distance,
      lastTouch: newTouch,
      finalDegree: state.finalDegree + distance,
    };
    e.preventDefault();
  }


  function onTouchStart(e) {
    if(state.started){
      return;
    }
    state = {
      ...state,
      started: true,
      lastTouch: calcCentralizedEvent(
        swipeElement,
        getTouchPoint(e).clientX,
        getTouchPoint(e).clientY,
      ),
    }

  }

  function onTouchEnd() {
    const randomWeight = state.lastDistance > 0 ? Math.random() * 10 : -Math.random() * 10;
    const DISTANCE_MODIFIER = 20;
    state = {
      ...state,
      started: false,
      finalDegree: state.finalDegree + (state.lastDistance * DISTANCE_MODIFIER) + randomWeight,
    };

    dispatch(TURN_EVENT_NAME, state);

  }
</script>
<div class="swipe-area"
     bind:this={swipeElement}
     on:touchmove={onTouchMove}
     on:touchstart={onTouchStart}
     on:touchend={onTouchEnd}
     on:mousedown={onTouchStart}
     on:mousemove={onTouchMove}
     on:mouseup={onTouchEnd}
>
    <Arrow started={state.started} finalDegree={state.finalDegree}/>
</div>


<style>
    .swipe-area {
        position: absolute;
        top: 50%;
        left: 50%;
        transform-style: preserve-3d;
        transform: translate(-50%, -50%);
        width: 100vmin;
        height: 100vmin;
    }
</style>