<script lang="ts">
  import {joinGame} from "../../api/game";
  import {navigate} from "svelte-routing";

  let isPending = false;

  function onSubmit(e) {
    if (isPending) {
      return;
    }
    isPending = true;
    joinGame(e.target.elements.game.value, e.target.elements.player.value)
      .then((joinGameResponse) => {
        isPending = false;
        navigate(`/room/${joinGameResponse.gameId}/player/${joinGameResponse.player.secret}`)
      })
      .catch(() => isPending = false)
  }
</script>
<div class="container">
    <h1 class="text-center text-2xl">
        Du trinkst!
    </h1>
    <form on:submit|preventDefault={onSubmit}
          class="grid grid-cols-1 gap-4 text-black text-center w-96 mx-auto">
        <p>
            <label for="game" class="block text-s text-white">Room</label>
            <input id="game" name="game" type="text" class="w-full" required autofocus>
        </p>
        <p>
            <label for="player" class="block text-s text-white">Player Name</label>
            <input id="player" name="player" type="text" class="w-full" required>
        </p>
        {#if isPending}
            <button class="border border-white rounded text-white" disabled>Loading ...</button>
        {:else}
            <button class="border border-white rounded text-white">Start</button>
        {/if}
    </form>
</div>
