export async function joinGame(gameName, playerName) {
    const response = await fetch('/api/game', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            gameName,
            playerName
        })
    });
    return response.json();
}
export async function logout(gameId, playerSecret) {
    const response = await fetch('/api/game/logout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            gameId,
            playerSecret
        })
    });
    return response.json();
}
export async function getGameData(gameId, playerSecret) {
    const response = await fetch(`/api/game/${gameId}/player/${playerSecret}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    });
    return response.json();
}
