<script lang="ts">
  import * as randomColor from 'randomcolor'
  import {calcTriangleCoordinatesFor} from "./utils";
  import {Player} from "../../api/model";


  export let players: Player[];
  const colors = randomColor.default({count: 50})
  $: playerData = (players||[]).map((player, index, array) => ({
    ...player,
    trianglePath: calcPathForPlayer(index, array.length),
    textPos: calcTextPosition(index, array.length, calcTriangleCoordinatesFor(index, array.length))
  }))
  $: console.log("PlayerData", playerData)
  function scale(i: number) {
    return Math.round(i * 100)
  }


  function calcPathForPlayer(i: number, maxLength: number): string {
    return calcTriangleCoordinatesFor(i, maxLength)
      .map(([x, y]) => `${scale(x)},${scale(y)}`)
      .join(" ")
  }

  function calcTextPosition(i: number, maxLength: number, triangleCoordinates: number[][]) {
    const sums = triangleCoordinates.reduce((acc, [x, y]) => {
      return [acc[0] + x, acc[1] + y];
    }, [0, 0])
    return {
      x: scale(sums[0] / 3),
      y: scale(sums[1] / 3)
    }
  }
</script>
<svg viewBox="-130 -130 260 260">
    <circle cx="0" cy="0" r="100" stroke="blue" fill="transparent" stroke-width="5"/>
    {#each playerData as {id, name, trianglePath, textPos}, i}
        <polygon points={trianglePath} stroke="black" fill={colors[i]}
                 fill-opacity="0.8" stroke-opacity="0.8"/>
    {/each}
    {#each playerData as {id, name, trianglePath, textPos}, i}
        <text text-anchor="middle" alignment-baseline="central" font-size="smaller" fill="white" stroke="black" stroke-width="0.5"
              x={textPos.x}
              y={textPos.y}>{name}</text>
    {/each}

</svg>
<style>
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform-style: preserve-3d;
        transform: translate(-50%, -50%);
        width: 100vmin;
        height: 100vmin;
    }
    @keyframes grow {
        0%   {transform: scaley(0) scalex(0)}
        100% {transform: scaley(1) scalex(1)}
    }

    polygon {
        animation: grow 0.5s forwards;
    }
    /*svg polygon{*/
    /*    transition: transform 2s;*/
    /*    transition-timing-function: cubic-bezier(0, 0, 0.3, 1);*/
    /*}*/
</style>

