<script lang="ts">
    export let finalDegree;
    export let started ;
    $: rotation = `transform: rotate(${finalDegree}rad); webkit-transform:rotate(${finalDegree}rad)`
</script>
<div class="spinning-arrow">
    <svg viewBox="0 0 200 100" preserveAspectRatio="none" style={rotation} class={[started ? '' : 'touch-up'].join(' ')}>
        <path d="M7,40
                l165,0
                a -5 -5 0 0 0 5 -5
                l0,-10
                l20,20
                a 3 3 0 0 1 0 5
                l-20,20
                l0,-10
                a 5 5 0 0 0 -5 -5
                l-165,0
                a 5 5 0 0 1 -5 -5
                l0,-5
                a 5 5 0 0 1 5 -5
                " stroke="yellow" fill="yellow" fill-opacity="0.7"/>
    </svg>

</div>

<style>
    .spinning-arrow{
        position: absolute;
        transform-style: preserve-3d;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

    }
    svg{
        transform-origin: 50% 50%;
        transition: transform 2s;
        transition-timing-function: linear;
        max-width: initial;
        height: 42vmin;
        -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    }
    svg.touch-up{
        transition: transform 2s;
        transition-timing-function: cubic-bezier(0, 0, 0.3, 1);
    }

</style>