<script lang="ts">
  import SwipeArea from "./SwipeArea.svelte";
  import PlayerCircle from "./PlayerCircle.svelte";
  import {getGameData, logout} from "../../api/game";
  import websocketStore from "../../api/websocketStore";
  import {onDestroy} from "svelte";
  import {getActivePlayer} from "./utils";

  export let gameId: string;
  export let playerSecret: string;
  let initialGameData
  let gameData
  let wsStore
  let closeWebsocketSubscription
  $: getGameData(gameId, playerSecret).then((gameInfoData) => {
    initialGameData = gameInfoData
  })
  $: if (initialGameData) {
    wsStore = websocketStore(initialGameData.websocketConnectionUrl, initialGameData, [])
    closeWebsocketSubscription = wsStore.subscribe((data) => {
      gameData = data
    })
  }
  onDestroy(() => {
    closeWebsocketSubscription && closeWebsocketSubscription();
    logout(gameId, playerSecret);
  })

  function sendTurn(event) {
    const rotation = event.detail.finalDegree
    wsStore.set({action: 'newTurn', rotation, gameId, playerSecret})
    console.log(getActivePlayer(rotation, gameData.players))

  }
</script>
{#if !initialGameData}
    <p>...Loading</p>
{:else}
    <PlayerCircle players={(gameData|| initialGameData).players}/>
    <SwipeArea initialRotation={(gameData|| initialGameData).rotation} on:turn={sendTurn}/>
{/if}

