<script>
	import './global.css'
	import { Router, Link, Route } from "svelte-routing";
	import Login from "./pages/login/Login.svelte";
	import Game from "./pages/game/Game.svelte";

	export let url = "";
</script>

<Router url="{url}">
	<nav>
		<Link to="/">Logout</Link>
	</nav>
	<main>
		<Route path="room/:gameId/player/:playerSecret" component="{Game}" />
		<Route path="/"><Login /></Route>
	</main>
</Router>