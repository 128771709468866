export function calcTriangleCoordinatesFor(i, maxLength) {
    if (maxLength === 1) {
        return [
            [Math.cos(0), Math.sin(0)],
            [Math.cos(2 * Math.PI / 3), Math.sin(2 * Math.PI / 3)],
            [Math.cos(4 * Math.PI / 3), Math.sin(4 * Math.PI / 3)]
        ];
    }
    if (maxLength === 2) {
        const phi1 = Math.PI / 2 * i * 2;
        const phi2 = Math.PI / 2 * (2 * i + 1);
        const phi3 = Math.PI / 2 * (2 * i + 2);
        return [
            [Math.cos(phi1), Math.sin(phi1)],
            [Math.cos(phi2), Math.sin(phi2)],
            [Math.cos(phi3), Math.sin(phi3)]
        ];
    }
    const phi1 = 2 * Math.PI / maxLength * i;
    const phi2 = 2 * Math.PI / maxLength * (i + 1);
    return [
        [0, 0],
        [Math.cos(phi1), Math.sin(phi1)],
        [Math.cos(phi2), Math.sin(phi2)]
    ];
}
export function getActivePlayer(rotation, players) {
    const TWO_PI = 2 * Math.PI;
    // Modulo of a negative number is negative, so mod operation is done twice
    const rotationModTwoPi = ((rotation % TWO_PI) + TWO_PI) % TWO_PI;
    const rotationScaledToOne = rotationModTwoPi / TWO_PI;
    const rotationScaledToNumberOfPlayers = rotationScaledToOne * players.length;
    return players[Math.floor(rotationScaledToNumberOfPlayers)];
}
